export const ncbMandatory = [
    110001, 110002, 110003, 110004, 110005,
    110006, 110007, 110008, 110009, 110010,
    110011, 110012, 110013, 110014, 110015,
    110016, 110017, 110018, 110019, 110020,
    110021, 110022, 110023, 110024, 110025,
    110026, 110027, 110028, 110029, 110030,
    110031, 110032, 110033, 110034, 110035,
    110036, 110037, 110038, 110039, 110040,
    110041, 110042, 110043, 110044, 110045,
    110046, 110047, 110048, 110049, 110051,
    110052, 110053, 110054, 110055, 110056,
    110057, 110058, 110059, 110060, 110061,
    110062, 110063, 110064, 110065, 110066,
    110067, 110068, 110069, 110070, 110071,
    110072, 110073, 110074, 110075, 110076,
    110077, 110078, 110079, 110080, 110081,
    110082, 110083, 110084, 110085, 110086,
    110087, 110088, 110089, 110090, 110091,
    110092, 110093, 110094, 110095, 110096,
    110097, 110098, 110099, 110110, 121001,
    121002, 121003, 121004, 121005, 121006,
    121007, 121008, 121009, 121010, 121011,
    121012, 121013, 121014, 121015, 121021,
    121101, 121102, 121103, 121104, 121105,
    121106, 121107, 122001, 122002, 122003,
    122004, 122005, 122006, 122007, 122008,
    122009, 122010, 122011, 122012, 122013,
    122015, 122016, 122017, 122018, 122019,
    122021, 122022, 122050, 122051, 122052,
    122098, 122100, 122101, 122102,
    122103, 122104, 122105, 122106, 122107,
    122108, 122109, 122211, 122413, 122414,
    122502, 122503, 122504, 122505, 122506,
    122508, 123003, 123401, 123413, 123414,
    123502, 123504, 123505, 123506, 201001,
    201002, 201003, 201004, 201005, 201006,
    201007, 201008, 201009, 201010, 201011,
    201012, 201013, 201014, 201015, 201016,
    201017, 201018, 201019, 201020, 201021,
    201101, 201102, 201103, 201110, 201201,
    201204, 201205, 201206, 201207, 201301,
    201302, 201303, 201304, 201305, 201306,
    201307, 201308, 201309, 201310, 201311,
    201312, 201313, 201314, 201315, 201316,
    201317, 201318, 203135, 203141, 203155,
    203201, 203202, 203203, 203207, 203208,
    203209, 245101, 245102, 245201, 245205,
    245207, 245208, 245301, 245304, 392150,
    393050, 393125, 394010, 394101, 394105,
    394107, 394110, 394111, 394112, 394120,
    394125, 394130, 394140, 394150, 394155,
    394160, 394163, 394170, 394180, 394185,
    394190, 394210, 394220, 394221, 394230,
    394235, 394240, 394245, 394246, 394248,
    394250, 394270, 394305, 394310, 394315,
    394317, 394320, 394325, 394326, 394327,
    394330, 394335, 394340, 394345, 394350,
    394352, 394355, 394360, 394365, 394370,
    394375, 394380, 394405, 394410, 394421,
    394430, 394440, 394445, 394510, 394515,
    394516, 394517, 394518, 394520, 394530,
  394540, 394541, 394550, 394601, 394620,
  394630, 394633, 394635, 394640, 394641,
  394650, 394651, 394655, 394660, 394670,
  394680, 394690, 394715, 394716, 395001,
  395002, 395003, 395004, 395005, 395006,
  395007, 395008, 395009, 395010, 395011,
  395012, 395013, 395017, 395023, 395345,
  395620, 396510, 400001, 400002, 400003,
  400004, 400005, 400006, 400007, 400008,
  400009, 400010, 400011, 400012, 400013,
  400014, 400015, 400016, 400017, 400018,
  400019, 400020, 400021, 400022, 400023,
  400024, 400025, 400026, 400027, 400028,
  400029, 400030, 400031, 400032, 400033,
  400034, 400035, 400036, 400037, 400038,
  400039, 400040, 400041, 400042, 400043,
  400046, 400047, 400048, 400049, 400050,
  400051, 400052, 400053, 400054, 400055,
  400056, 400057, 400058, 400059, 400060,
  400061, 400062, 400063, 400064, 400065,
  400066, 400067, 400068, 400069, 400070,
  400071, 400072, 400074, 400075, 400076,
  400077, 400078, 400079, 400080, 400081,
  400082, 400083, 400084, 400085, 400086,
  400087, 400088, 400089, 400090, 400091,
  400092, 400093, 400094, 400095, 400096,
  400097, 400098, 400099, 400101, 400102,
  400103, 400104, 400105, 400125, 400202,
  400209, 400218, 400303, 400401, 400547,
  400601, 400602, 400603, 400604, 400605,
  400606, 400607, 400608, 400609, 400610,
  400611, 400612, 400613, 400614, 400615,
  400701, 400702, 400703, 400704, 400705,
  400706, 400707, 400708, 400709, 400710,
  400802, 401101, 401102, 401103, 401104,
  401105, 401106, 401107, 401109, 401201,
  401202, 401203, 401204, 401205, 401206,
  401207, 401208, 401209, 401301, 401302,
  401303, 401304, 401305, 401401, 401402,
  401403, 401404, 401405, 401406, 401407,
  401493, 401501, 401502, 401503, 401504,
  401505, 401506, 401513, 401601, 401602,
  401603, 401604, 401605, 401606, 401607,
  401608, 401609, 401610, 401701, 401702,
  401703, 401709, 410206, 410208, 410209,
  410210, 410218, 421001, 421002, 421003,
  421004, 421005, 421101, 421102, 421103,
  421105, 421201, 421202, 421203, 421204,
  421205, 421206, 421209, 421301, 421302,
  421303, 421304, 421305, 421306, 421308,
  421311, 421312, 421321, 421352, 421401,
  421402, 421403, 421405, 421501, 421502,
  421503, 421504, 421505, 421506, 421601,
  421602, 421603, 421604, 421605, 410221,
  500036, 500064, 500008, 500045, 500004, 
  500033, 500082, 500028, 500018, 500081, 
  500096, 500041, 500034, 500038, 500073, 
  500109, 500012, 500066, 500058, 500059, 
  500044, 500029, 500001, 500063, 500095, 
  500022, 500031, 500006, 500057, 500110, 
  500053, 500065, 500023, 500020, 500024, 
  500027, 500015, 500003, 500084, 500107, 
  500002, 500080, 500007, 500016, 500025, 
  500010, 500014, 500017, 500026, 500056, 
  500061, 500011, 500094, 500040, 500009, 
  500021, 500071, 500133, 500134, 500138, 
  500171, 500175, 500178, 500195, 500030, 
  500013, 501201, 501301, 500048, 500005, 
  500039, 500060, 500062, 500068, 500052, 
  500051, 500035, 500076, 500079, 500085, 
  500077, 500067, 500074, 500069, 500070, 
  501101, 500091, 500075, 500046, 500089, 
  500032, 500092, 500086, 500097, 500098, 
  500043, 500101, 501102, 500047, 500037, 
  500078, 500050, 501401, 500042, 500054, 
  500055, 500087, 500088, 500100, 500072, 
  500049, 500083, 500090, 500093
  ];
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { goDigitEnviroment } from './../../environments/environment';
import { CommonService } from './common.service';
import { master_pincode_go_digit } from '../goDigitConfig/goDigitMasterPincode'


@Injectable({
    providedIn: 'root',
  })

  export class CalculateQuotegoDigit {
    constructor( private commonService: CommonService ) {}


        quickQuote(PlanCode): Observable<any> {
         const url = goDigitEnviroment.quoteEndPoint

         const payload = {"payload":this.createPayload(PlanCode)}
         return this.commonService.post(url,payload)
        }
    
      private createPayload (PlanCode) {
        const tab = localStorage.getItem('tab')
        let userData 
        let policyType
        let relationship:string
        if(tab === 'I') {
            userData = JSON.parse(localStorage.getItem('userData1'));
            policyType ='NONFLOATER'
            relationship ="Self"
        } else if (tab === 'F') {
            userData = JSON.parse(localStorage.getItem('userData2'));
            policyType='FLOATER';
        }
        let insured_member = JSON.parse(localStorage.getItem('proposal_Proposer'))
        let startDate = this.commonService.calculatePolicyStartDate()
        let member = JSON.parse(localStorage.getItem('userData2Insured'))
        let zone = master_pincode_go_digit.filter(pinCode => parseInt(userData.pincode) === pinCode.Pincode)
        let persons = [];
        let digitEnquiryId =this.enquiryId()
        localStorage.setItem('digitEnquiryId', digitEnquiryId)
        member[0].form.forEach(memberItem => {
            let dob;
          
            if (tab === "I") {
                dob = this.commonService.calculateAge(userData.dob);
            } else {
                dob = this.commonService.calculateAge(memberItem.DateOfBirth);
                relationship = memberItem.relation
            }
        
            let person = {
                "addresses": [
                    {
                        "pincode": userData.pincode,
                        "zone": zone[0].Zone
                    }
                ],
                "relationship": relationship,
                "coverages": [
                    {
                        "coverType": goDigitEnviroment.coverTypeHLINP,
                        "sumInsuredAmount": userData.coverAmount
                    },
                    {
                        "coverType": goDigitEnviroment.coverTypeRHIMR,
                        "sumInsuredAmount": userData.coverAmount
                    }
                ],
                "age": dob
            };
            persons.push(person);
        });
        
        const payload  ={
            "retailHealthQuickQuote": {
                "persons": [],
                "partnerName": "AGENT",
                "contract": {
                    "sumInsured": userData.coverAmount,
                    "nonAbsProductCode": PlanCode,
                    "zone": zone[0].Zone,
                    "policyType":policyType,
                    "subInsuranceProductCode": "HLCP",
                    "paymentFrequency": null,
                    "policyPeriod": parseInt(userData.tenure,10),
                    "startDate": startDate,
                    "expiryDate": this.commonService.calculatePolicyEndDate(startDate,userData.tenure)
                },
                "headerParam": {
                    "Authorization": goDigitEnviroment.Authorization
                },
                "enquiryId": digitEnquiryId
            }
        }
        payload.retailHealthQuickQuote.persons = persons
        return payload
    }
    enquiryId() {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let result = '';
        
        for (let i = 0; i < 21; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            result += characters.charAt(randomIndex);
        }
        
        return result;
    }

  }


  @Injectable({
    providedIn: "root",
  })
  export class ProposalForGodigit {
    constructor(
        private createEnquiryID:CalculateQuotegoDigit,
        private commonService: CommonService) {}

        createProposal(goDoigitAddons):Observable<any> {
        const url = goDigitEnviroment.proposalEndPoint
        const payload = {"payload":this.createPayload(goDoigitAddons)}
        localStorage.setItem('proposal_payload', JSON.stringify(payload))
        const savePayloads = '/payloads/addPayloads'
        const payloadData = {
        "g_id": localStorage.getItem('g_id'),
        "transaction_id": localStorage.getItem('digitEnquiryId'),
        "user_id": localStorage.getItem('getquoteuserID'),
        'proposal_payload': payload,
        'insurance_provider': localStorage.getItem('platform')
        }
        this.commonService.post(savePayloads,payloadData).subscribe()
        return this.commonService.post(url,payload)
       }

    private createPayload(goDoigitAddons) {
        
        let startDate = this.commonService.calculatePolicyStartDate()
        let tab = localStorage.getItem('tab')
        let proposer = JSON.parse(localStorage.getItem('proposal_Proposer'));
        let DocumentType
        if(proposer.IDProofType === 'PAN_CARD' ) {
            DocumentType = "D07"
        } else if(proposer.IDProofType === 'AADHAR_CARD') {
            DocumentType = "D03"
        } else if(proposer.IDProofType === 'DRIVING_LICENSE'){
            DocumentType = "D04"
        }else if (proposer.IDProofType ==='PASSPORT') {
            DocumentType = "D06"
        }else if(proposer.IDProofType==='VOTER_ID_CARD'){
            DocumentType = "D05"
        }
        let policyType
        let userData
        if(tab === 'I') {
            userData = JSON.parse(localStorage.getItem('userData1'));
            policyType ='NONFLOATER'
        } else if (tab === 'F') {
            userData = JSON.parse(localStorage.getItem('userData2'));
            policyType='FLOATER';
        }
        let premium = JSON.parse(localStorage.getItem(localStorage.getItem('platform')))
        let proposal_insured_member = JSON.parse(localStorage.getItem('proposal_insured_member'))
        let nominee = JSON.parse(localStorage.getItem('proposal_nominee'))
        let persons = [] 
        let basePlanCoverage =  [
            {
                "coverType": goDigitEnviroment.coverTypeRHIMR,
                "sumInsuredAmount":userData.coverAmount 
            },

            {
                "coverType": goDigitEnviroment.coverTypeHLINP,
                "sumInsuredAmount": userData.coverAmount
            }
        ]
        let updatedCovers = goDoigitAddons
        .filter(cover => cover.showMembers) 
        .map(cover => {
          if (cover.CoverType === "53634") {
            cover.sumInsuredAmount = "10";
          } else if (cover.CoverType === "48734") {
            cover.sumInsuredAmount = userData.tenure;
          }
          return {
            coverType: cover.CoverType,
            sumInsuredAmount: cover.sumInsuredAmount
          };
        });

      const  coverageWithaddons = [...basePlanCoverage, ...updatedCovers];
      
        proposal_insured_member.forEach(member=>{
          let medical = []
          if (member.selectedDiseaseIds) {
            medical = member.selectedQuestions.map(question => {
                return {
                    questionCode: question.DiseaseID || '',  
                    answerType: question.Answer_Type || 'BOOLEAN',
                    isApplicable: true,  
                    detailAnswer: "TRUE",  
                    subQuestions: question.subQuestions.map(subQuestion => {
                        return {
                            questionCode: subQuestion.DiseaseID || '',  
                            answerType: subQuestion.Answer_Type || 'BOOLEAN',  
                            isApplicable: true, 
                            detailAnswer: subQuestion.Answer_Description || "FALSE",
                            subQuestions: []  
                        }
                    })
                }
            });
        }
            const documentId = member.IDProofNumber.toUpperCase();
            let isPrimaryInsuredPerson: boolean
            let isPolicyHolder: boolean
            let isPayer: boolean
            let isPrimaryPolicyHolder: boolean
            if(member.relationship_with_user==='self') {
                isPrimaryInsuredPerson= true
                isPolicyHolder =true
                isPayer = true
                isPrimaryPolicyHolder = true
            } else {
                isPrimaryInsuredPerson= false
                isPolicyHolder = false
                isPayer = false
                isPrimaryPolicyHolder =  false
            }
            const person = {
                "personType": "NATURALPERSON",
                "uniqueIdentifier": null,
                "addresses": [
                    {
                        "street":`${proposer.address.house_no} ${proposer.address.area}`,
                        "city": proposer.address.city,
                        "state": proposer.address.state,
                        "pincode": proposer.address.pincode
                    }
                ],
                "communications": [
                    {
                        "communicationType": "EMAIL",
                        "communicationId": proposer.emailId,
                        "isPrefferedCommunication": true
                    },
                    {
                        "communicationType": "MOBILE",
                        "communicationId": proposer.contactNumber,
                        "isPrefferedCommunication": true
                    }
                ],
                "identificationDocuments": [
                    {
                        "issuingAuthority": "Govt of India",
                        "issuingPlace": "IN",
                        "issueDate" :member.IDProofIssueDate,
                        "documentType": member.IDProofType,
                        "documentId":documentId
                    }
                ],
                "isPrimaryInsuredPerson": isPrimaryInsuredPerson,
                "isPolicyHolder": isPolicyHolder,
                "isPayer": isPayer,
                "isInsuredPerson": true,
                "isPrimaryPolicyHolder": isPrimaryPolicyHolder,
                "nominee": {
                    "firstName": nominee.first_name,
                    "middleName": null,
                    "lastName":  nominee.last_name,
                    "dateOfbirth": nominee.date_of_birth,
                    "profession": null,
                    "gender":nominee.gender,
                    "relationship": nominee.relationship_with_proposer
                },
                "firstName": member.first_name,
                "middleName": null,
                "lastName": member.last_name,
                "dateOfbirth": `${member.date_of_birth.yy}-${member.date_of_birth.mm}-${member.date_of_birth.dd}`,
                "profession":member.occupation,
                "gender":member.gender,
                "relationship":member.relationship_with_user.toUpperCase(),
                "maritalStatus": member.marital_status === "Single" ? "L" : member.marital_status,
                "physicalCharacteristics": {
                    "height": this.commonService.heightToCms(member.height.ft, member.height.inch),
                    "weight": member.weight
                },
                "coverages": [
                    {
                        "coverType": goDigitEnviroment.coverTypeRHIMR,
                        "sumInsuredAmount":userData.coverAmount
                    },
                    {
                        "coverType": goDigitEnviroment.coverTypeHLINP,
                        "sumInsuredAmount": userData.coverAmount
                    }
                ],
                "medical": {
                    "medicalQuestions": medical
                }
            }
            persons.push(person)  
        })
        const plan = goDigitEnviroment.ProductsCode.filter(plan=> plan.PlanName=== localStorage.getItem('platform'))
        const enquiryId = this.createEnquiryID.enquiryId()
        const payload = {
            "retailHealthCreateQuote": {
                "enquiryId": enquiryId,
                "partnerName": "AGENT",
                "headerParam": {
                    "Authorization":goDigitEnviroment.Authorization
                },
                "contract": {
                    "policyType": policyType,
                    "startDate": startDate,
                    "policyNumber": null,
                    "subInsuranceProductCode": "HLCP",
                    "nonAbsProductCode":plan[0].PlanCode,
                    "policyPeriod": premium.premium_year
                },
                "payment": {
                    "paymentType": "ONLINE",
                    "paymentDate":startDate
                },
                "persons": persons,
                "contractCoverages": coverageWithaddons,
                "kyc": {
                    "isKYCDone": false,
                    "ckycReferenceDocId": DocumentType,
                    "ckycReferenceNumber": proposer.IDProofNumber,
                    "dateOfBirth":`${proposer.date_of_birth.yy}-${proposer.date_of_birth.mm}-${proposer.date_of_birth.dd}`,
                    "photo": "",
                    "successReturnURL": `${window.location.origin}${goDigitEnviroment.kycReturnUrl}`,
                    "failureReturnURL": `${window.location.origin}${goDigitEnviroment.kycReturnUrl}`

                }
            }
        }
      return payload
    }
  }

  @Injectable({
    providedIn: "root",
  })

  export class StatusAndDownloadDocument {
    constructor( private commonService: CommonService ) {}
    private digiturl = goDigitEnviroment.goDigitAPIEndPoint
    Response (policyNum): Observable<any> {
  
        const paylaod  ={ "payload":
        { "retailHealthKYCStatusAPI": {
             "queryParam": {
                 "policyNumber":policyNum
             }
         }}
        }
        return this.commonService.post(this.digiturl+"kycStatus", paylaod );
    }

    downloadDocument (policyNum): Observable<any> {
        const paylaod  = {"payload":
                {
                "retailHealthPDFService": {
                    "headerParam": {
                    "Authorization": goDigitEnviroment.Authorization },
                    "pathParam": {
                    "policyNumber": policyNum}
                    }
               }
            }  
        return this.commonService.post(this.digiturl+"documentDownload", paylaod)
    }

     checkPolicyStatus(policyNum): Observable<any> {
        return this.commonService.get(this.digiturl+"checkPolicyStatus/"+policyNum)
    }

  }
